import { Roles } from '@/models/user/roles'
import { DecryptedRegistrant } from '@/models/user/user'
import axios, { AxiosResponse } from 'axios'
import {
  APIError,
  AuthError,
  BadRequest,
  NotFoundError,
  PermissionsError
} from './error_service'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_NODE_URL

export default class AuthService {
  public async login (email: string, pw: string): Promise<{ role: Roles }> {
    let user = {} as { role: Roles }
    try {
      const res: AxiosResponse = await axios.post('/auth/login', {
        email: email,
        password: pw
      })
      user = res.data.data
      if (user.role === Roles.Removed) {
        throw new PermissionsError("You do not have the proper permissions to continue.", 200);
      }
    } catch (err) {
      
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        )
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status)
      } else {
        throw new APIError(
          'Something went wrong, please try again.',
          err.response ? err.response.status : 500
        )
      }
    }
    return user
  }

  public async register (first: string, last: string, email: string, pw: string, optionalData?: {repEmail?: string, role: Roles, company?: string}): Promise<void> {
   let body = {
      first_name: first,
      last_name: last,
      email: email,
      password: pw
    };
    if (optionalData) {
      body = Object.assign({}, {...body}, {...optionalData})
    }
    try {
      await axios.post('/auth/v/register', body)
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        )
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status)
      } else {
        throw new APIError(
          err.response.data.message,
          err.response.status
        )
      }
    }
  }

  public async approveNewUser(firstName: string, lastName: string, company: string, userEmail: string, repEmail: string, key: string): Promise<void> {
    const body = {
      first_name: firstName,
      last_name: lastName,
      company: company,
      user_email: userEmail,
      rep_email: repEmail,
      key: key
    };
    try {
      await axios.post('/auth/v/approve', body);
    }catch(err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        )
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status)
      } else {
        throw new APIError(
          err.response.data.message,
          err.response.status
        )
      }
    }
  }

  public async checkAuth (): Promise<{ role: Roles }> {
    let user = {} as { role: Roles }
    try {
      const res: AxiosResponse = await axios.get('/auth/checkauth')
      user = res.data.data
    } catch (err) {
      throw new AuthError(err.response.data.message, err.response.status)
    }
    return user
  }

  public async logout (): Promise<void> {
    try {
      await axios.post('/auth/logout')
    } catch (err) {
      throw new APIError(
        'Something went wrong, please try again.',
        err.response.status
      )
    }
  }

  public async requestNewPassword (email: string): Promise<void> {
    try {
      await axios.patch('/auth/v/forgot-password', { email: email })
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 401) {
        throw new AuthError(err.response.data.message, err.response.status)
      } else {
        throw new APIError(
          'Something went wrong, please try again.',
          err.response.status
        )
      }
    }
  }

  public async resetPassword (
    newPw: string,
    token: string,
    userId: string
  ): Promise<{ role: Roles}> {
    let res
    try {
      res = await axios.post('/auth/v/reset-password', {
        password: newPw,
        token: token,
        userId: userId
      })
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        )
      } else {
        throw new APIError(
          'Something went wrong, please try again.',
          err.response.status
        )
      }
    } return res.data.data
  }

  public async resendEmailVerification (email: string): Promise<void> {
    try {
      await axios.post('/auth/v/resend-verify', { email: email })
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        )
      } else {
        throw new APIError(
          'Something went wrong, please try again.',
          err.response.status
        )
      }
    }
  }

  public async verifyRegistration (
    token: string,
    userId: string
  ): Promise<{ role: Roles}> {
    let res
    try {
      res = await axios.get(`/auth/v/verify?token=${token}&id=${userId}`)
    } catch (err) {
      if (err.response && err.response.status === 400) {
        throw new BadRequest(err.response.data.message, err.response.status)
      } else if (err.response && err.response.status === 403) {
        throw new PermissionsError(
          err.response.data.message,
          err.response.status
        )
      } else {
        throw new APIError(
          'Something went wrong, please try again.',
          err.response.status
        )
      }
    }
    return res.data.data
  }

  public async readEncryptedKey(key: string): Promise<DecryptedRegistrant> {
    let data = {} as { firstName: string, lastName: string, userEmail: string, company: string, role: Roles, repEmail: string };
    try {
      const res: AxiosResponse = await axios.get(`/auth/v/decrypt?key=${encodeURI(key)}`)
      data = res.data.data
    } catch (err) {
      throw new AuthError(err.response.data.message, err.response.status)
    }
    return data;
  }

  public async postCsvForEncryption(csv: FormData): Promise<any> {
    let data = ''
    try {
      const res: AxiosResponse = await axios.post('/auth/v/encrypt?csv=true', csv)
      data = res.data.data;
    } catch (err) {
      throw new AuthError(err.response.data.message, err.response.status)
    }
    return data;
  }

  public async postUserForEncryption(userInfo: unknown): Promise<any> {
    let data = [];
    let key = '';
    try {
      const res: AxiosResponse = await axios.post('auth/v/encrypt', userInfo)
      data = res.data.data; // returns the array of objects that contain the first name, last name, company, email and the encrypted key
      key = data[0].key;
    } catch (err) {
      throw new AuthError(err.response.data.message, err.response.status)
    }
    return key;
  }
}
