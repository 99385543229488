
import { Vue, Component } from 'vue-property-decorator'
import Header from "@/components/Header.vue"

@Component({
  components: {
    Header
  }
})
export default class App extends Vue {
  get staging(): boolean {
    if (process.env.VUE_APP_ENV === "staging") {
      return true;
    } else {
      return false;
    }
  }
}
