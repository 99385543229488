
import AuthService from "@/services/auth_service";
import { GenericError } from "@/services/error_service";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  protected authService = new AuthService();
  protected async logout(): Promise<void> {
    try {
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      GenericError.popup(err.message);
    }
  }
}
