export enum Roles {
  Admin = 'admin',
  CustomerService = 'customerservice',
  SalesRep = 'rep', 
  SalesManager = 'salesmanager',
  Basic = 'basic',
  RetailerLevel1 = 'retailer1',
  Unverified = 'unverified',
  Removed = 'removed'
}
