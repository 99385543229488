import Vue from "vue";
import Vuetify, { VApp, VCard, VTextField } from "vuetify/lib";
import '@mdi/font/css/materialdesignicons.css' 
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify, {
  components: {
    VApp,
    VCard,
    VTextField
  }
});

const opts = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#333F48",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107"
      }
    }
  }
};

export default new Vuetify(opts);
