// import '@/components'
import './assets/css/uikit.min.css'
import 'vue-loading-overlay/dist/vue-loading.css'
import Loading from 'vue-loading-overlay'
import App from './App.vue'
import Vue from 'vue'
import router from './router'
import vuetify from './plugins/vuetify'
// @ts-ignore
import Icons from '../node_modules/uikit/dist/js/uikit-icons.min.js'
import UIkit from 'uikit'

// @ts-ignore
UIkit.use(Icons)
Vue.use(
  Loading,
  {
    color: '#333F48',
    loader: 'dots',
    canCancel: false,
    isFullPage: true,
    opacity: 0.8
  },
  {}
)
Vue.component('loading', Loading)
Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
